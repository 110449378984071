import styled from 'styled-components';
import { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { useNavigate, NavigateOptions } from 'react-router-dom';

import Heading from 'components/Form/Heading';
import Input from 'components/Form/Input'
import Button from 'components/Form/Button';
import { StyledCard } from 'components/Form/Card';
import Footer from 'components/misc/Footer';
import FancyBackground from 'components/misc/FancyBackground';

import docs from 'utils/docs';
import colors from 'styles/colors';
import { determineAddressType } from 'utils/address-type-checker';
import Nav from 'components/Form/Nav';

const HomeContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: 'PTMono';
  padding: 0 1rem;
  footer {
    z-index: 1;
  }
`;

const UserInputMain = styled.form`
  background: ${colors.backgroundLighter};
  box-shadow: 4px 4px 0px ${colors.bgShadowColor};
  border-radius: 8px;
  padding: 1rem;
  z-index: 5;
  margin: 1rem;
  width: calc(100% - 2rem);
  max-width: 50rem;
  z-index: 2;
`;
const HeaderLinkContainer = styled.nav`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  a {
    text-decoration: none;
  }
`;

// const FindIpButton = styled.a`
//   margin: 0.5rem;
//   cursor: pointer;
//   display: block;
//   text-align: center;
//   color: ${colors.primary};
//   text-decoration: underline;
// `;

const ErrorMessage = styled.p`
  color: ${colors.danger};
  margin: 0.5rem;
`;

const SiteFeaturesWrapper = styled(StyledCard)`
  margin: 1rem;
  width: calc(100% - 2rem);
  max-width: 50rem;
  z-index: 2;
  .links {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    a {
      width: 100%;
      button {
        width: calc(100% - 2rem);
      }
    }
    @media(max-width: 600px) {
      flex-wrap: wrap;
    }
  }
  ul {
    -webkit-column-width: 150px;
    -moz-column-width: 150px;
    column-width: 150px;
    list-style: none;
    padding: 0 1rem;
    font-size: 0.9rem;
    li {
      margin: 0.1rem 0;
      text-indent: -1.2rem;
      break-inside: avoid-column;
    }
    li:before {
      content: '✓';
      color: ${colors.primary};
      margin-right: 0.5rem;
    }
  }
  a {
    color: ${colors.primary};
  }
`;

const Signup = (): JSX.Element => {
  const defaultPlaceholder = 'e.g. https://rootxran.com/';
  const [userEmail, setUserEmail] = useState('');
  const [userPass, setUserPass] = useState('');
  const [errorMsg, setErrMsg] = useState('');
  const [inputDisabled] = useState(false);
  const navigate = useNavigate();




  useEffect(() => {
		const token = localStorage.getItem('token')
		if (token) {
			const checkToken = async () => {
        const response = await fetch('https://wcapi.rootxran.com/api/verifytoken', {
            method: 'GET',
            headers: {
                'x-access-token': token,
            }
        })
    
        const data = await response.json()
    
        if (data.error) {
            navigate('/login')
        }
        else
        {
          navigate('/')
        }
      }
      checkToken();
		}
	}, [])
  /* Check is valid address, either show err or redirect to results page */
  const submit = () => {
    // let address = userInput.endsWith("/") ? userInput.slice(0, -1) : userInput;
    // const addressType = determineAddressType(address);
  
    // if (addressType === 'empt') {
    //   setErrMsg('Field must not be empty');
    // } else if (addressType === 'err') {
    //   setErrMsg('Must be a valid URL, IPv4 or IPv6 Address');
    // } else {
    //   // if the addressType is 'url' and address doesn't start with 'http://' or 'https://', prepend 'https://'
    //   if (addressType === 'url' && !/^https?:\/\//i.test(address)) {
    //     address = 'https://' + address;
    //   }
    //   const resultRouteParams: NavigateOptions = { state: { address, addressType } };
    //   navigate(`/results/${encodeURIComponent(address)}`, resultRouteParams);
    // }
  };
  

  /* Update user input state, and hide error message if field is valid */
  const emailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserEmail(event.target.value);
  };

  const passChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserPass(event.target.value);
  };
  // const findIpAddress = () => {
  //   setUserInput('');
  //   setPlaceholder('Looking up your IP...');
  //   setInputDisabled(true);
  //   fetch('https://ipapi.co/json/')
  //     .then(function(response) {
  //       response.json().then(jsonData => {
  //         setUserInput(jsonData.ip);
  //         setPlaceholder(defaultPlaceholder);
  //         setInputDisabled(true);
  //       });
  //     })
  //     .catch(function(error) {
  //       console.log('Failed to get IP address :\'(', error)
  //     });
  // };

  const formSubmitEvent = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const response = await fetch('https://wcapi.rootxran.com/api/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userEmail,
            userPass,
        }),
    })

    const data = await response.json()

    if (data.status === 'ok') {
        navigate('/login')
    }
  }

  const handleLogout = () => {
    // event.preventDefault();

  }

  return (
    <HomeContainer>
      {/* <Nav>
        <div>
        <form onSubmit={handleLogout}>
          <input style={{fontSize: '30px', cursor: 'pointer'}} type='submit' value='Logout' />
        </form>

        </div>
      </Nav> */}
      <Nav>
        <HeaderLinkContainer>
            <a href='/login'><Button onClick={handleLogout}>Login</Button></a>
        </HeaderLinkContainer>
      </Nav>
      <UserInputMain style={{marginBottom: '10%', marginTop: '7%'}} onSubmit={formSubmitEvent}>
        <Heading as="h1" size="xLarge" align="center" color={colors.primary}>
          {/* <img style={{ width: "584px", height: "100px" }} src="/rootxran_logo.png" alt="Web Check Icon" /> */}
          Sign Up
        </Heading>
        <Input
          id="user-input"
          value={userEmail}
          label="Enter email"
          size="large"
          orientation="vertical"
          placeholder="Enter email"
          disabled={inputDisabled}
          handleChange={emailChange}
          inputType='email'
        />
        <Input
          id="user-input"
          value={userPass}
          label="Enter a password"
          size="large"
          orientation="vertical"
          placeholder="*******"
          disabled={inputDisabled}
          handleChange={passChange}
          inputType='password'
        />
        {/* <FindIpButton onClick={findIpAddress}>Or, find my IP</FindIpButton> */}
        { errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
        <Button styles="width: calc(100% - 1rem);" size="large" bgColor='emarld'>Sign up</Button>
      </UserInputMain>
    </HomeContainer>
  );
}

export default Signup;
